import "core-js/stable";
import "regenerator-runtime/runtime";

import "./js/config.js";

// Load in vendor scripts (jquery loaded by Webpack directly);
import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

if (document.querySelector('body.site-layout') != null) {
    import('./js/scrolling');
    import('./js/keyboard-accessibility-check');
    import('./js/favourites');
}

if (document.querySelector('.header--menu') != null) {
    import('./js/main-navigation');
}

if (document.querySelector('#header--search-form') != null) {
    import('./js/header-search');
}

if (document.querySelector('#off-canvas-basket') != null) {
    import('./js/basket');
}

if (document.querySelector('.btn-addtocart') != null) {
    import('./js/addtocart-button');
}

if (document.getElementById("edit-address-form") != null) {
    import("./js/edit-addresses-form");
}

// if (document.querySelector(".color-quiz") != null) {
//     import("./js/color-quiz");
// }


// Load in custom JS modules on initial page load and when DOM is updated

let brand = 'tints'; // default
const getBrand = document.body.dataset.brand;
if(getBrand != undefined && getBrand != brand) brand = getBrand;

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`,'');
    });
}

const jsModuleCheck = () => {

    let testQuery = null;

    // smart-bootstrap-tabs
    testQuery = document.querySelector('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
        addDataJsParsed(thisDomQuery, "smart-bootstrap-tabs");
        import('./js/smart-bootstrap-tabs').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // defer-iframe-content.js
    testQuery = document.querySelector('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
        addDataJsParsed(thisDomQuery, "defer-iframe-content");
        import('./js/defer-iframe-content').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // better-than-native bootstrap carousel swipe detection
    testQuery = document.querySelector('.carousel:not([data-js-parsed--bs-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.carousel:not([data-js-parsed--bs-carousel])');
        addDataJsParsed(thisDomQuery, "bs-carousel");
        import('./js/bootstrap-carousel-swipe').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // data-sizes-js-fix (fixes lazysizes issue)
    testQuery = document.querySelector('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
        addDataJsParsed(thisDomQuery, "data-sizes-js-fix");
        import('./js/data-sizes-js-fix').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // list-carousel
    testQuery = document.querySelector('.list-carousel:not([data-js-parsed--list-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.list-carousel:not([data-js-parsed--list-carousel])');
        addDataJsParsed(thisDomQuery, "list-carousel");
        import('./js/list-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - vimeo
    testQuery = document.querySelector('.hero--video.vimeo:not([data-js-parsed--vimeo])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.vimeo:not([data-js-parsed--vimeo])');
        addDataJsParsed(thisDomQuery, "vimeo");
        import('./js/hero-video-vimeo').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - youtube
    testQuery = document.querySelector('.hero--video.youtube:not([data-js-parsed--youtube])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.youtube:not([data-js-parsed--youtube])');
        addDataJsParsed(thisDomQuery, "youtube");
        import('./js/hero-video-youtube').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // collapse - expand for desktop
    testQuery = document.querySelector('.expand-for-desktop:not([data-js-parsed--expand-for-desktop])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.expand-for-desktop:not([data-js-parsed--expand-for-desktop])');
        addDataJsParsed(thisDomQuery, "expand-for-desktop");
        import('./js/collapse-expand-for-desktop').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // products-swiper
    testQuery = document.querySelector('.products-swiper:not([data-js-parsed--products-swiper])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.products-swiper:not([data-js-parsed--products-swiper])');
        addDataJsParsed(thisDomQuery, "products-swiper");
        import('./js/products-swiper').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // product-gallery
    testQuery = document.querySelector('.product-gallery:not([data-js-parsed--product-gallery])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.product-gallery:not([data-js-parsed--product-gallery])');
        addDataJsParsed(thisDomQuery, "product-gallery");
        import('./js/product-gallery').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // form number control
    testQuery = document.querySelector('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
        addDataJsParsed(thisDomQuery, "form-number-control");
        import('./js/form-number-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // testimonials-swiper
    testQuery = document.querySelector('#testimonials-swiper:not([data-js-parsed--testimonials-swiper])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('#testimonials-swiper:not([data-js-parsed--testimonials-swiper])');
        addDataJsParsed(thisDomQuery, "testimonials-swiper");
        import('./js/testimonials-swiper');
    }

    // marquee-scroller
    testQuery = document.querySelector('.marquee-scroller:not([data-js-parsed--marquee-scroller])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.marquee-scroller:not([data-js-parsed--marquee-scroller])');
        addDataJsParsed(thisDomQuery, "marquee-scroller");
        import('./js/marquee-scroller').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // filter listing
    testQuery = document.querySelector('.listing-live-filtering:not([data-js-parsed--listing-live-filtering])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.listing-live-filtering:not([data-js-parsed--listing-live-filtering])');
        addDataJsParsed(thisDomQuery, "listing-live-filtering");
        import('./js/listing-live-filtering').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // video modal listing links
    testQuery = document.querySelector('[data-video-modal-src]:not([data-js-parsed--video-modal-link])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('[data-video-modal-src]:not([data-js-parsed--video-modal-link])');
        addDataJsParsed(thisDomQuery, "video-modal-link");
        import('./js/video-modal.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // --------------------------
    // ----- JS/CSS Modules -----
    // --------------------------

    // responsive-nav-bar
    testQuery = document.querySelector('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
        addDataJsParsed(thisDomQuery, "responsive-nav-bar");
        import(`./modules/responsive-nav-bar/responsive-nav-bar--${brand}.js`).then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive-table
    testQuery = document.querySelector('.responsive-table:not([data-js-parsed--responsive-table])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-table:not([data-js-parsed--responsive-table])');
        addDataJsParsed(thisDomQuery, "responsive-table");
        import(`./modules/responsive-table/responsive-table--${brand}.js`);
    }

    // instagram-layout (.section--gridtiles)
    testQuery = document.querySelector('.section--gridtiles:not([data-js-parsed--instagram-layout])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.section--gridtiles:not([data-js-parsed--instagram-layout])');
        addDataJsParsed(thisDomQuery, "instagram-layout");
        import(`./modules/instagram-layout/instagram-layout--${brand}.js`);
    }

    // lightboxes (PhotoSwipe v5 - galleries and single images)
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
        addDataJsParsed(thisDomQuery, "lightbox");
        import(`./modules/lightboxes-galleries/lightboxes--${brand}.js`).then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // color-quiz
    testQuery = document.querySelector('.color-quiz:not([data-js-parsed--color-quiz])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.color-quiz:not([data-js-parsed--color-quiz])');
        addDataJsParsed(thisDomQuery, "color-quiz");
        import(`./modules/color-quiz/color-quiz--${brand}.js`);
    }

};

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('main');
if(targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function(mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for(const mutation of mutationsList) {
            if (mutation.addedNodes && mutation.addedNodes.length > 0 && mutation.type === 'childList') {
                checkNeeded = true;
            }
        }
        if(checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 500);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}
